import _ from 'lodash';
import React from 'react';

import skLang from './sk.json';

interface Props {
    t: string;
}

export class Translation extends React.PureComponent<Props> {
    static t(path: string) {
        return _.get(skLang, path, path);
    }

    render() {
        return <span>{Translation.t(this.props.t)}</span>;
    }
}
