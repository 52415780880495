import { STATE } from 'src/data/houses';

const STATE_MAP = {
    [STATE.FREE]: {
        color: 'green',
        label: 'Voľný',
    },
    [STATE.RESERVED]: {
        color: 'orange',
        label: 'Rezervovaný',
    },
    [STATE.SOLD]: {
        color: 'red',
        label: 'Predaný',
    },
};

export function getColor(state: STATE): string {
    return STATE_MAP[state].color;
}

export function getLabel(state: STATE): string {
    return STATE_MAP[state].label;
}
