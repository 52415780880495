import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const ContactPage = () => (
    <Container className="pb-3 pt-5">
        <Row className="pb-3">
            <Col md={2} />
            <Col md={8} className="text-center">
                <img width={100} src="img/Logo.png" alt="logo" />
                <br />
                <h1 className="text-uppercase">HREŽĎOVSKÉ LÚKY</h1>
                <div className="pb-3" style={{ fontSize: 18 }}>
                    Nová obytná štvrť v pokojnom prostredí mesta Bánovce nad Bebravou
                </div>
                <Row>
                    <Col md={6} sm={12} className="mt-3">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href="http://www.hrezdovskeluky.sk" target="_blank">
                            www.hrezdovskeluky.sk
                        </a>
                        <br />
                        <a href="mailto:info@hrezdovskeluky.sk">info@hrezdovskeluky.sk</a>
                    </Col>
                    <Col md={6} sm={12} className="mt-3">
                        <a href="tel:+421911957016">+421 911 957 016</a>
                        <br />
                        od 8:00 do 16:00
                    </Col>
                </Row>
            </Col>
            <Col md={2} />
        </Row>

        <Row className="pt-5 pb-3">
            <Col md={2} />
            <Col md={8}>
                <div className="pb-3">
                    <h5>6 krokov k Vášmu novému bývaniu v Lokalite Hrežďovské lúky:</h5>
                </div>
                <ol className="pl-3 text-justify">
                    <li>
                        Dohodnite si osobné stretnutie s našim zástupcom kde Vás detailne oboznámime
                        s projektom Hrežďovské lúky
                    </li>
                    <li>Vyberte si konkrétny rodinný dom a pozemok podľa Vašich preferencií</li>
                    <li>
                        Podpíšte Rezervačnú zmluvu a uhraďte rezervačný poplatok 10 000,-€ a môžete
                        v kľude začať pracovať na zabezpečení financovania
                    </li>
                    <li>
                        Do 30 dní, pri podpise Zmluvy o budúcej kúpnej zmluvy, doplatíte do výšky
                        20% z ceny nehnuteľnosti
                    </li>
                    <li>
                        Po zrealizovaní hrubej stavby domu zaplatíte ďalšiu splátku domu vo výške
                        50% z ceny nehnuteľnosti
                    </li>
                    <li>
                        Po dokončení domu do štádia „holodom“, resp. „dom na kľúč“ Vás vyzveme k
                        podpisu kúpnej zmluvy a následne k zaplateniu zostávajúcej časti kúpnej
                        ceny. Po úhrade Vám odovzdáme Vašu novú nehnuteľnosť
                    </li>
                </ol>
            </Col>
            <Col md={2} />
        </Row>
    </Container>
);
