import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel, { ModalGateway, Modal } from 'react-images';

const PHOTO_1 = '/img/FHD_Photo - 69.jpg';
const PHOTO_2 = '/img/FHD_Photo - 42.jpg';
const PHOTO_3 = '/img/FHD_Photo - 96.jpg';

export const AboutPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

    return (
        <Container className="pb-3">
            <h1 className="text-center m-4">O projekte</h1>

            <Row className="pb-3">
                <Col>
                    <img
                        className="d-block w-100 btn"
                        src={PHOTO_1}
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelectedPhotoIndex(0);
                        }}
                    />
                </Col>
                <Col md={5} className="d-flex align-items-center">
                    <p className="text-justify">
                        Vždy ste túžili po priestrannom a modernom bývaní v dome s vlastnou
                        záhradou? Hrežďovské lúky je unikátny projekt novostavieb v Bánovciach nad
                        Bebravou, kde získate všetko, čo ste si kedy priali. Moderná architektúra,
                        precízne a detailné vyhotovenie, použité kvalitné materiály a výborné
                        dispozičné riešenia - to všetko tvorí dokonalé bývanie bez kompromisov.
                    </p>
                </Col>
            </Row>

            <Row className="pb-3">
                <Col md={5} className="d-flex align-items-center">
                    <p className="text-justify">
                        Moderné rodinné domy sme vytvorili ako pre aktívnych ľudí, ktorí ocenia
                        možnosti rekreácie, kultúrneho a športového vyžitia v okolí, tak pre rodiny
                        s riešením komunitného súkromia celého projektu. Mestská časť Malé Chlievany
                        Vám navyše zaručuje jednoduchý prístup do centra mesta za 15 minút pohodlnou
                        chôdzou. Mimoriadny dôraz kladieme na prvotriedny servis tak, aby sa
                        zákazník cítil výnimočný a aby sme v čo najvernejšej podobe dokázali
                        pretaviť jeho sen o bývaní na skutočnosť.
                    </p>
                </Col>
                <Col>
                    <img
                        className="d-block w-100 btn"
                        src={PHOTO_2}
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelectedPhotoIndex(1);
                        }}
                    />
                </Col>
            </Row>

            <Row className="pb-3">
                <Col>
                    <img
                        className="d-block w-100 btn"
                        src={PHOTO_3}
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelectedPhotoIndex(2);
                        }}
                    />
                </Col>
                <Col md={5} className="d-flex align-items-center">
                    <p className="text-justify">
                        V ponuke je 14 samostatne stojacich rodinných domov a 4 sekcie
                        dvojpodlažných trojdomov. Pri samostne stojacich domoch je možnosť výberu
                        varianty rodinného domu s garážou alebo krytým parkovacím státim. Pri
                        dvojpodlažnych domoch je garáž súčasťou domu. Zeleň prislúchajúca k
                        rodinnému domu závisí od veľkosti pozemku. Súčasťou lokality je výhľadovo
                        plánovaná oddychová zóna.
                    </p>
                </Col>
            </Row>

            <ModalGateway>
                {isModalOpen ? (
                    <Modal onClose={() => setIsModalOpen(false)}>
                        <Carousel
                            views={[{ source: PHOTO_1 }, { source: PHOTO_2 }, { source: PHOTO_3 }]}
                            currentIndex={selectedPhotoIndex}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </Container>
    );
};
