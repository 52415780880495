import React from 'react';
import { Carousel } from 'react-bootstrap';

import { Translation } from 'src/language/Translation';

import style from 'src/components/SlideShow.module.scss';

export const SlideShow = () => (
    <Carousel>
        <Carousel.Item>
            <img className="d-block w-100" src="/img/FHD_Photo - 3.jpg" alt="First slide" />
            <Carousel.Caption className={style.carouselCaption}>
                <h3>{Translation.t('home.slideShow[0].title')}</h3>
                <p>{Translation.t('home.slideShow[0].description')}</p>
            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
            <img className="d-block w-100" src="/img/FHD_Photo - 42.jpg" alt="Third slide" />
            <Carousel.Caption className={style.carouselCaption}>
                <h3>{Translation.t('home.slideShow[1].title')}</h3>
                <p>{Translation.t('home.slideShow[1].description')}</p>
            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
            <img className="d-block w-100" src="/img/FHD_Photo - 69.jpg" alt="Third slide" />
            <Carousel.Caption className={style.carouselCaption}>
                <h3>{Translation.t('home.slideShow[2].title')}</h3>
                <p>{Translation.t('home.slideShow[2].description')}</p>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
);
