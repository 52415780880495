import React from 'react';
import { STATE } from 'src/data/houses';
import { getColor, getLabel } from 'src/components/StateLabelService';

interface Props {
    state: STATE;
}

export const StateLabel: React.FC<Props> = ({ state }) => (
    <span style={{ fontWeight: 'bold', color: getColor(state) }}>{getLabel(state)}</span>
);
