import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Content } from 'src/layout/Content';
import { Footer } from 'src/layout/Footer';
import { Header } from 'src/layout/Header';
import './App.css';

const App = () => {
    return (
        <BrowserRouter>
            <Header />
            <Content />
            <Footer />
        </BrowserRouter>
    );
};

export default App;
