import React from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { AreaFormat } from 'src/components/AreaFormat';
import { MoneyFormat } from 'src/components/MoneyFormat';
import { StateLabel } from 'src/components/StateLabel';
import { HOUSES } from 'src/data/houses';

export const PricesPage: React.FC = () => {
    return (
        <Container className="pb-3">
            <h1 className="text-center m-4">Cenník</h1>

            <Table responsive borderless>
                <thead className="border-bottom border-top">
                    <th>Názov</th>
                    <th>Typ a počet izieb</th>
                    <th>Pozemok</th>
                    <th>Úžitková plocha</th>
                    <th>Celková zastrešená plocha</th>
                    <th>Stav</th>
                    <th>Cena - holodom</th>
                    <th>Cena - štandart</th>
                    <th></th>
                </thead>
                <tbody>
                    {HOUSES.map(house => (
                        <tr>
                            <td className="font-weight-bold text-nowrap">{house.name}</td>
                            <td>{house.rooms}</td>
                            <td>
                                <AreaFormat value={house.landArea} />
                            </td>
                            <td>
                                <AreaFormat value={house.usefulArea} />
                            </td>
                            <td>
                                <AreaFormat value={house.totalHouseArea} />
                            </td>
                            <td>
                                <StateLabel state={house.state} />
                            </td>
                            <td>
                                <MoneyFormat value={house.price} />
                            </td>
                            <td>
                                <MoneyFormat value={house.price + house.priceExtra} />
                            </td>
                            <td>
                                <Button href={`/house/${house.name}`} size={'sm'}>
                                    Zobraziť
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};
