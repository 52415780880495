import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel, { ModalGateway, Modal } from 'react-images';
import imageList from '../imageList.json';

const MAX_IMAGES_IN_ROW = 4;

export const GalleryPage: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

    const {
        images: { all: images },
    } = imageList;

    return (
        <Container className="pb-3">
            <h1 className="text-center m-4">Galéria</h1>

            {images.map((image, i) => {
                if (i % MAX_IMAGES_IN_ROW !== 0) {
                    return null;
                }

                return (
                    <Row>
                        <Col
                            xs={12}
                            sm={6}
                            md={3}
                            onClick={() => {
                                setIsModalOpen(true);
                                setSelectedPhotoIndex(i);
                            }}
                            key={images[i]}
                            className="p-1"
                        >
                            <img src={images[i]} className="w-100" style={{ cursor: 'pointer' }} />
                        </Col>
                        {images[i + 1] && (
                            <Col
                                xs={12}
                                sm={6}
                                md={3}
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedPhotoIndex(i + 1);
                                }}
                                key={images[i + 1]}
                                className="p-1"
                            >
                                <img
                                    src={images[i + 1]}
                                    className="w-100"
                                    style={{ cursor: 'pointer' }}
                                />
                            </Col>
                        )}
                        {images[i + 2] && (
                            <Col
                                xs={12}
                                sm={6}
                                md={3}
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedPhotoIndex(i + 2);
                                }}
                                key={images[i + 2]}
                                className="p-1"
                            >
                                <img
                                    src={images[i + 2]}
                                    className="w-100"
                                    style={{ cursor: 'pointer' }}
                                />
                            </Col>
                        )}
                        {images[i + 3] && (
                            <Col
                                xs={12}
                                sm={6}
                                md={3}
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedPhotoIndex(i + 3);
                                }}
                                key={images[i + 3]}
                                className="p-1"
                            >
                                <img
                                    src={images[i + 3]}
                                    className="w-100"
                                    style={{ cursor: 'pointer' }}
                                />
                            </Col>
                        )}
                    </Row>
                );
            })}

            <ModalGateway>
                {isModalOpen && (
                    <Modal onClose={() => setIsModalOpen(false)}>
                        <Carousel
                            currentIndex={selectedPhotoIndex}
                            views={images.map(image => ({
                                source: image,
                            }))}
                        />
                    </Modal>
                )}
            </ModalGateway>
        </Container>
    );
};
