import React from 'react';
import { useLocation } from 'react-router';
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import { HOUSES, TYPE } from 'src/data/houses';
import { Translation } from 'src/language/Translation';

function getTypes(): TYPE[] {
    return Object.keys(TYPE) as TYPE[];
}

function getHouses(type: TYPE) {
    return HOUSES.filter(house => house.type === type);
}

export const Header = () => {
    const { pathname } = useLocation();

    return (
        <div>
            <Navbar variant="dark" bg="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <Image
                            src="/logo.png"
                            style={{ maxHeight: '23px', padding: '0 5px 3px 5px' }}
                        />
                        <Translation t="header.title" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/" active={pathname === '/'}>
                                {Translation.t('header.linkHome')}
                            </Nav.Link>
                            <NavDropdown
                                title={Translation.t('header.linkOffer')}
                                id="basic-nav-dropdown"
                                active={pathname.includes('/house/')}
                            >
                                {getTypes().map((type, index) => {
                                    const isLastType = index === getTypes().length - 1;

                                    return (
                                        <>
                                            {getHouses(type).map(house => (
                                                <NavDropdown.Item href={`/house/${house.name}`}>
                                                    {house.name}
                                                </NavDropdown.Item>
                                            ))}
                                            {!isLastType && <NavDropdown.Divider />}
                                        </>
                                    );
                                })}
                            </NavDropdown>
                            <Nav.Link href="/locality" active={pathname === '/locality'}>
                                {Translation.t('header.linkLocality')}
                            </Nav.Link>
                            <Nav.Link href="/prices" active={pathname === '/prices'}>
                                {Translation.t('header.linkPrices')}
                            </Nav.Link>
                            <Nav.Link href="/gallery" active={pathname === '/gallery'}>
                                {Translation.t('header.linkGallery')}
                            </Nav.Link>
                            <Nav.Link href="/about" active={pathname === '/about'}>
                                {Translation.t('header.about')}
                            </Nav.Link>
                            <Nav.Link href="/contact" active={pathname === '/contact'}>
                                {Translation.t('header.contact')}
                            </Nav.Link>
                        </Nav>
                        {/*<Form inline>*/}
                        {/*    <FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
                        {/*    <Button variant="outline-light">Search</Button>*/}
                        {/*</Form>*/}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};
