import { HOUSE_DESCRIPTIONS } from 'src/data/houseDescriptions';
import { Standard_DUO_A1A2_B1B2 } from 'src/data/standard_DUO_A1-A2_B1-B2';
import { Standard_MONO_A1A4 } from 'src/data/standard_MONO_A1-A4';
import { Standard_MONO_B1B4 } from 'src/data/standard_MONO_B1-B4';
import { STANDARD_SOLO_1 } from 'src/data/standard_SOLO_1';
import { STANDARD_SOLO_2 } from 'src/data/standard_SOLO_2';
import { Standard_TRIO } from 'src/data/standard_TRIO';
import { RowData } from 'src/data/types';
import imageList from '../imageList.json';

export enum TYPE {
    SOLO = 'SOLO',
    MONO = 'MONO',
    DUO = 'DUO',
    TRIO = 'TRIO',
}

export enum STATE {
    FREE = 'FREE',
    RESERVED = 'RESERVED',
    SOLD = 'SOLD',
}

export enum PARKING_TYPE {
    GARAGE = 'GARAGE',
    COVERED_PARKING = 'COVERED_PARKING',
}

export interface House {
    name: string;
    state: STATE;
    type: TYPE;
    commissioning?: string;
    rooms?: string;
    landArea: number;
    usefulArea: number;
    totalHouseArea: number;
    terraceArea: number | number[];
    price: number;
    priceExtra: number;
    description: string;
    standardData: RowData[];
    pdf?: string;
    pdfFloor0?: string;
    pdfFloor1?: string;
    parkingType: PARKING_TYPE;
    images: string[];
}

export const HOUSES: House[] = [
    {
        name: 'Solo 1',
        state: STATE.RESERVED,
        type: TYPE.SOLO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 655,
        usefulArea: 96.39,
        totalHouseArea: 133.68,
        terraceArea: 40.14,
        parkingType: PARKING_TYPE.COVERED_PARKING,
        price: 171500,
        priceExtra: 13900,
        standardData: STANDARD_SOLO_1,
        pdf: 'SOLO_1',
        description: HOUSE_DESCRIPTIONS.SOLO_1,
        images: imageList.images.solo1,
    },
    {
        name: 'Solo 2',
        state: STATE.FREE,
        type: TYPE.SOLO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 765,
        usefulArea: 142.48,
        totalHouseArea: 227.95,
        terraceArea: 0,
        parkingType: PARKING_TYPE.COVERED_PARKING,
        price: 251000,
        priceExtra: 18800,
        standardData: STANDARD_SOLO_2,
        pdf: 'SOLO_2',
        description: HOUSE_DESCRIPTIONS.SOLO_2,
        images: imageList.images.solo2,
    },
    {
        name: 'Mono A1',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 600,
        usefulArea: 119.33,
        totalHouseArea: 144.86,
        terraceArea: 18,
        parkingType: PARKING_TYPE.COVERED_PARKING,
        price: 183900,
        priceExtra: 15700,
        standardData: Standard_MONO_A1A4,
        pdf: 'MONO_A1',
        description: HOUSE_DESCRIPTIONS.MONO_A,
        images: imageList.images.monoA,
    },
    {
        name: 'Mono A2',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 600,
        usefulArea: 119.33,
        totalHouseArea: 144.86,
        terraceArea: 18,
        parkingType: PARKING_TYPE.COVERED_PARKING,
        price: 183900,
        priceExtra: 15700,
        standardData: Standard_MONO_A1A4,
        pdf: 'MONO_A2',
        description: HOUSE_DESCRIPTIONS.MONO_A,
        images: imageList.images.monoA,
    },
    {
        name: 'Mono A3',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 600,
        usefulArea: 119.33,
        totalHouseArea: 144.86,
        terraceArea: 18,
        parkingType: PARKING_TYPE.COVERED_PARKING,
        price: 183900,
        priceExtra: 15700,
        standardData: Standard_MONO_A1A4,
        pdf: 'MONO_A3',
        description: HOUSE_DESCRIPTIONS.MONO_A,
        images: imageList.images.monoA,
    },
    {
        name: 'Mono A4',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 600,
        usefulArea: 119.33,
        totalHouseArea: 144.86,
        terraceArea: 18,
        parkingType: PARKING_TYPE.COVERED_PARKING,
        price: 183900,
        priceExtra: 15700,
        standardData: Standard_MONO_A1A4,
        pdf: 'MONO_A4',
        description: HOUSE_DESCRIPTIONS.MONO_A,
        images: imageList.images.monoA,
    },
    {
        name: 'Mono B1',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 600,
        usefulArea: 141.57,
        totalHouseArea: 145.57,
        terraceArea: 18,
        parkingType: PARKING_TYPE.GARAGE,
        price: 194900,
        priceExtra: 17800,
        standardData: Standard_MONO_B1B4,
        pdf: 'MONO_B1',
        description: HOUSE_DESCRIPTIONS.MONO_B,
        images: imageList.images.monoB,
    },
    {
        name: 'Mono B2',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 600,
        usefulArea: 141.57,
        totalHouseArea: 145.57,
        terraceArea: 18,
        parkingType: PARKING_TYPE.GARAGE,
        price: 194900,
        priceExtra: 17800,
        standardData: Standard_MONO_B1B4,
        pdf: 'MONO_B2',
        description: HOUSE_DESCRIPTIONS.MONO_B,
        images: imageList.images.monoB,
    },
    {
        name: 'Mono B3',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 600,
        usefulArea: 141.57,
        totalHouseArea: 145.57,
        terraceArea: 18,
        parkingType: PARKING_TYPE.GARAGE,
        price: 194900,
        priceExtra: 17800,
        standardData: Standard_MONO_B1B4,
        pdf: 'MONO_B3',
        description: HOUSE_DESCRIPTIONS.MONO_B,
        images: imageList.images.monoB,
    },
    {
        name: 'Mono B4',
        state: STATE.FREE,
        type: TYPE.MONO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 636,
        usefulArea: 141.57,
        totalHouseArea: 145.57,
        terraceArea: 18,
        parkingType: PARKING_TYPE.GARAGE,
        price: 198000,
        priceExtra: 17800,
        standardData: Standard_MONO_B1B4,
        pdf: 'MONO_B4',
        description: HOUSE_DESCRIPTIONS.MONO_B,
        images: imageList.images.monoB,
    },
    {
        name: 'Duo A1',
        state: STATE.FREE,
        type: TYPE.DUO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 861,
        usefulArea: 171.45,
        totalHouseArea: 202.11,
        terraceArea: 22.94,
        parkingType: PARKING_TYPE.GARAGE,
        price: 256000,
        priceExtra: 21000,
        standardData: Standard_DUO_A1A2_B1B2,
        pdf: 'DUO_A1',
        description: HOUSE_DESCRIPTIONS.DUO,
        images: imageList.images.duo,
    },
    {
        name: 'Duo A2',
        state: STATE.FREE,
        type: TYPE.DUO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 741,
        usefulArea: 171.45,
        totalHouseArea: 202.11,
        terraceArea: 22.94,
        parkingType: PARKING_TYPE.GARAGE,
        price: 246000,
        priceExtra: 21000,
        standardData: Standard_DUO_A1A2_B1B2,
        pdf: 'DUO_A2',
        description: HOUSE_DESCRIPTIONS.DUO,
        images: imageList.images.duo,
    },
    {
        name: 'Duo B1',
        state: STATE.FREE,
        type: TYPE.DUO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 724,
        usefulArea: 171.45,
        totalHouseArea: 202.11,
        terraceArea: 22.94,
        parkingType: PARKING_TYPE.GARAGE,
        price: 244400,
        priceExtra: 21000,
        standardData: Standard_DUO_A1A2_B1B2,
        pdf: 'DUO_B1',
        description: HOUSE_DESCRIPTIONS.DUO,
        images: imageList.images.duo,
    },
    {
        name: 'Duo B2',
        state: STATE.FREE,
        type: TYPE.DUO,
        rooms: 'samostatne stojaci 4 + KK',
        landArea: 861,
        usefulArea: 171.45,
        totalHouseArea: 202.11,
        terraceArea: 22.94,
        parkingType: PARKING_TYPE.GARAGE,
        price: 256000,
        priceExtra: 21000,
        standardData: Standard_DUO_A1A2_B1B2,
        pdf: 'DUO_B2',
        description: HOUSE_DESCRIPTIONS.DUO,
        images: imageList.images.duo,
    },
    {
        name: 'Trio A1',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 505,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 216800,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_A123',
        pdfFloor1: 'TRIO_2_A123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio A2',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 397,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 207700,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_A123',
        pdfFloor1: 'TRIO_2_A123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio A3',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 465,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 213400,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_A123',
        pdfFloor1: 'TRIO_2_A123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio B1',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 447,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 211900,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_B123',
        pdfFloor1: 'TRIO_2_B123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio B2',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 397,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 207700,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_B123',
        pdfFloor1: 'TRIO_2_B123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio B3',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 451,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 212200,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_B123',
        pdfFloor1: 'TRIO_2_B123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio C1',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 451,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 212200,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_C123',
        pdfFloor1: 'TRIO_2_C123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio C2',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 397,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 207700,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_C123',
        pdfFloor1: 'TRIO_2_C123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio C3',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 447,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 211900,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_C123',
        pdfFloor1: 'TRIO_2_C123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio D1',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 447,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 211900,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_D123',
        pdfFloor1: 'TRIO_2_D123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio D2',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 397,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 207700,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_D123',
        pdfFloor1: 'TRIO_2_D123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
    {
        name: 'Trio D3',
        state: STATE.FREE,
        type: TYPE.TRIO,
        rooms: 'radový 4 + KK',
        landArea: 451,
        usefulArea: 170.52,
        totalHouseArea: 177.33,
        terraceArea: [26.13, 13.6],
        parkingType: PARKING_TYPE.GARAGE,
        price: 212200,
        priceExtra: 21300,
        standardData: Standard_TRIO,
        pdfFloor0: 'TRIO_1_D123',
        pdfFloor1: 'TRIO_2_D123',
        description: HOUSE_DESCRIPTIONS.TRIO,
        images: imageList.images.trio,
    },
];
