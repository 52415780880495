import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { SiOpenstreetmap } from 'react-icons/si';

const PHOTO_SITUATION = '/img/situacia.png';
const PHOTO_SITUATION_MAP = '/img/situacia-mapa.png';
const PHOTO_SITUATION_MAP_ZOOM = '/img/situacia-mapa-zoom.png';

export const LocalityPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

    return (
        <Container className="pb-3">
            <Row className="pt-3">
                <Col md={6} xs={12} className="overflow-hidden">
                    <img
                        className="w-100 btn"
                        style={{ marginTop: -30, marginBottom: -30 }}
                        src={PHOTO_SITUATION}
                        alt="situacia"
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelectedPhotoIndex(0);
                        }}
                    />
                </Col>
                <Col md={6} xs={12}>
                    <img
                        className="w-100 btn mt-2"
                        src={PHOTO_SITUATION_MAP}
                        alt="situacia-mapa"
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelectedPhotoIndex(1);
                        }}
                    />
                    <img
                        className="w-100 btn"
                        src={PHOTO_SITUATION_MAP_ZOOM}
                        alt="situacia-mapa-zoom"
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelectedPhotoIndex(2);
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-center pt-5">
                    <Button
                        variant="primary"
                        href="https://goo.gl/maps/bhxjQTygAFWpyM5h7"
                        target="_blank"
                    >
                        Otvoriť na Google Maps <SiOpenstreetmap />
                    </Button>
                </Col>
            </Row>

            <ModalGateway>
                {isModalOpen && (
                    <Modal onClose={() => setIsModalOpen(false)}>
                        <Carousel
                            views={[
                                { source: PHOTO_SITUATION },
                                { source: PHOTO_SITUATION_MAP },
                                { source: PHOTO_SITUATION_MAP_ZOOM },
                            ]}
                            currentIndex={selectedPhotoIndex}
                        />
                    </Modal>
                )}
            </ModalGateway>
        </Container>
    );
};
