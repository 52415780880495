export const HOUSE_DESCRIPTIONS = {
    MONO_A:
        'Do domu sa vchádza cez zastrešené závetrie do vstupnej chodby- haly s dostatočným priestorom na vstavanú skriňu a k dispozícii je samostatné WC s umývadlom. Do dennej a nočnej časti domu sa prechádza chodbou. Denná časť je dispozične riešená cez otvorené priestory. Tvorí ju kuchyňa a obývacia izba (vrátane jedálne) s miestom pre osadenie krbovej vložky a s východom na terasu a do záhrady. Z obývacej izby sa dá taktiež prejsť (okrem prechodu z chodby) do nočnej časti, ktorú tvoria 3 izby- spálňa z východom na terasu a do záhrady a 2 detské izby. Medzi izbami je situovaná kúpeľňa s oknom, v ktorej je priestor pre vaňu, sprchu, WC, umývadlo a práčku. Súčasťou domu je kryté parkovanie pre 1 vozidlo, pričom pred domom je možnosť pre 2 parkovacie státia.',
    MONO_B:
        'Do domu sa vchádza cez zastrešené závetrie do vstupnej chodby- haly s dostatočným priestorom na vstavanú skriňu a k dispozícii je samostatné WC s umývadlom. Do dennej a nočnej časti domu sa prechádza chodbou. Denná časť je dispozične riešená cez otvorené priestory. Tvorí ju kuchyňa a obývacia izba (vrátane jedálne) s miestom pre osadenie krbovej vložky a s východom na terasu a do záhrady. Z obývacej izby sa dá taktiež prejsť (okrem prechodu z chodby) do nočnej časti, ktorú tvoria 3 izby- spálňa s východom na terasu a do záhrady a 2 detské izby. Medzi izbami je situovaná kúpeľňa s oknom, v ktorej je priestor pre vaňu, sprchu, WC, umývadlo a práčku. Súčasťou domu je garáž pre 1 vozidlo s prechodom do vstupnej chodby- haly, pričom pred domom je možnosť pre 2 parkovacie státia.',
    DUO:
        'Do domu sa vchádza cez zastrešené závetrie do vstupného zádveria- haly s dostatočným priestorom na vstavanú skriňu a k dispozícii je samostatné WC s umývadlom. Z chodby-haly sa prechádza do dennej časti domu. Denná časť je dispozične riešená cez otvorené priestory. Tvorí ju kuchyňa so vstupom do komory, jedáleň a obývacia izba s miestom pre osadenie krbovej vložky a s východom na prednú terasu vrátane krytej časti. Z obývacej izby sa dá prejsť do 1. samostatného bloku nočnej časti, ktorý tvoria 2 izby (detské izby)- obe s východom na prednú terasu, chodba, kúpeľňa s oknom, v ktorej je priestor pre vaňu a umývadlá a samostatné WC s umývadlom. Z jedálne sa dá cez chodbu prejsť do 2. samostatného bloku nočnej časti, ktorý tvorí 1 izba (spálňa), šatník a kúpeľňa s oknom, v ktorej je priestor pre vaňu, sprchu, WC a umývadlo. Súčasťou domu je garáž (vrátane skladu) pre 1 vozidlo, pričom pred domom je možnosť pre 2 parkovacie státia.',
    TRIO:
        'Do domu sa vchádza na prízemí (1. NP) cez zastrešené závetrie do vstupného zádveria s dostatočným priestorom na vstavanú skriňu a k dispozícii je samostatné WC s umývadlom. Denná časť je dispozične riešená cez otvorené priestory. Tvorí ju kuchyňa so vstupom do komory a obývacia izba (vrátane jedálne) s miestom pre osadenie krbovej vložky a s východom na terasu a do záhrady. Ďalej dennú časť tvorí práčovňa a sklad s východom na terasu a do záhrady. Súčasťou domu je garáž pre 1 vozidlo s prechodom do technickej miestnosti pod časťou schodiska, pričom pred domom je možnosť pre 2 parkovacie státia. \n' +
        'Do nočnej časti na poschodí (2. NP) sa prechádza schodiskom z obývacej izby prízemia cez chodbu.  Tvoria ju 3 izby- spálňa a 2 detské izby. Z chodby je prístupná kúpeľňa s oknom, v ktorej je priestor pre vaňu, sprchu a umývadlo, samostatné WC s umývadlom, sklad a taktiež prístup na terasu nad časťou garáže.',
    SOLO_1:
        'Do domu sa vchádza cez zastrešené závetrie do vstupného zádveria s dostatočným priestorom na vstavanú skriňu. Do dennej a nočnej časti domu sa prechádza chodbou. Denná časť na pravej strane je dispozične riešená cez otvorené priestory. Tvorí ju kuchyňa so vstupom do komory a obývacia izba (vrátane jedálne) s miestom pre osadenie krbovej vložky a s východom na terasu a do záhrady. Nočnú časť po ľavej strane tvoria 3 izby- spálňa s prístupom do šatníka a 2 detské izby. Pri izbách je situovaná kúpeľňa s oknom, v ktorej je priestor pre vaňu, sprchu, WC a umývadlo. Na chodbe je taktiež technická miestnosť. Súčasťou domu je kryté parkovanie pre 1 vozidlo, pričom pred domom je možnosť pre 2 parkovacie státia.',
    SOLO_2:
        'Do domu sa vchádza z krytého parkovania do vstupného zádveria- haly s dostatočným priestorom na vstavanú skriňu a k dispozícii je samostatné WC s umývadlom a technická miestnosť. Do dennej a nočnej časti domu sa prechádza chodbami. Denná časť na ľavej strane je dispozične riešená cez otvorené priestory. Tvorí ju kuchyňa s jedálňou a so vstupom do komory, obývacia izba s miestom pre osadenie krbovej vložky a s východom na krytú terasu a do záhrady a chodba. Nočnú časť po pravej strane tvoria 3 izby- spálňa s prístupom do šatníka a 2 detské izby- obe s východom na krytú terasu, chodba. Pri izbách je situovaná kúpeľňa s oknom, v ktorej je priestor pre vaňu, sprchu, WC, umývadlo a práčku. Súčasťou domu je kryté parkovanie pre 2 vozidlá, pričom pred domom je možnosť pre 2 parkovacie státia.',
};
