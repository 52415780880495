import React from 'react';
import NumberFormat from 'react-number-format';

interface Props {
    value: number;
}

export const MoneyFormat: React.FC<Props> = ({ value }) => {
    return (
        <NumberFormat
            value={value}
            suffix=" €"
            thousandSeparator=" "
            displayType="text"
            className="text-nowrap"
        />
    );
};
