import { RowData } from 'src/data/types';

export const STANDARD_SOLO_1: RowData[] = [
    {
        name: 'ZÁKLADY RD',
        details: [
            {
                name: 'základové pásy',
                description: 'z betónu v kombinácii s debniacimi tvárnicami',
            },
            {
                name: 'základová doska',
                description: 'podkladová zo železobetónu zo štrkopieskovým lôžkom',
            },
            {
                name: 'hydroizolácie',
                description: 'izolácia proti zemnej vlhkosti a radónu',
            },
        ],
    },
    {
        name: 'ZVISLÉ NOSNÉ KONŠTRUKCIE RD',
        details: [
            {
                name: 'obvodové murivo',
                description: 'stavebný systém YTONG, resp. PORFIX na lepidlo hr. 300 mm',
            },
            {
                name: 'otvorové preklady',
                description: 'typové – nosné a nenosné preklady',
            },
        ],
    },
    {
        name: 'VODOROVNÉ NOSNÉ KONŠTRUKCIE',
        details: [
            {
                name: 'stropná doska',
                description:
                    'polomotovaná s nosníkov a vložiek so zmonolitnením zálievkou, resp. železobetónová monolitická',
            },
            {
                name: 'obvodový veniec',
                description:
                    'zo železobetónu v úrovni stropu opatrený tepelnou izoláciou hr. 50 mm vloženou do debnenia',
            },
        ],
    },
    {
        name: 'VNÚTORNÉ PRIEČKY A MURIVÁ',
        details: [
            {
                name: 'deliace priečky',
                description: 'stavebný systém YTONG resp. PORFIX na lepidlo hr. 100 mm a 150 mm',
            },
            {
                name: 'nosné murivá',
                description: 'stavebný systém YTONG resp. PORFIX na lepidlo hr. 200 mm a 250 mm',
            },
        ],
    },
    {
        name: 'STRECHA',
        details: [
            {
                name: 'plochá strecha',
                description:
                    'spádová,  odvodnená do  dažďových zvodov, s tepelnou izoláciou z EPS hr. min. 300 mm (v krytom parkovaní a závetrí podľa projektu) a so strešnou krytinou z mPVC fólie',
            },
        ],
    },
    {
        name: 'KLAMPIARSKE KONŠTRUKCIE',
        details: [
            {
                name: 'strecha',
                description: 's povrchovou úpravou napr. systém KJG a poplastované plechy',
            },
            {
                name: 'fasádne prvky',
                description: 's povrchovou úpravou napr. systém KJG',
            },
        ],
    },
    {
        name: 'KOMÍNOVÉ TELESO',
        details: [
            {
                name: 'komín',
                description: 'komínový systém - príprava pre krbovú vložku  s výkonom 4-12 kW',
            },
        ],
    },
    {
        name: 'FASÁDA',
        details: [
            {
                name: 'steny',
                description:
                    'kontaktný zatepľovací systém s tepelným izolantom EPS SF hr. 150 mm a štruktúrovanou omietkou (farba podľa projektu) a z časti obkladom (podľa projektu)',
            },
            {
                name: 'sokel',
                description:
                    'kontaktný  zatepľovací  systém  s tepelným  izolantom  XPS  hr. 100 mm a povrchovou omietkou (vo farbe podľa projektu)',
            },
        ],
    },
    {
        name: 'PODHĽAD',
        details: [
            {
                name: 'znížený podhľad',
                description: 'sadrokartónový pohľad',
            },
        ],
    },
    {
        name: 'VNÚTORNÉ OMIETKY',
        details: [
            {
                name: '',
                description: 'interiérová vápenno-cementová omietka',
            },
        ],
    },
    {
        name: 'MAĽBY',
        details: [
            {
                name: '',
                description: 'biela maľba stien a sadrokartónových pohľadov 2-násobná',
            },
        ],
    },
    {
        name: 'PODLAHY',
        details: [
            {
                name: 'miestnosti',
                description:
                    'tepelná  izolácia a cementový poter vhodný pre podlahové elektrické vykurovanie',
            },
            {
                name: 'vonkajšie prekryté závetrie',
                description: 'betónová mazanina pod zámkovú dlažbu',
            },
            {
                name: 'kryté parkovanie',
                description: 'betónová mazanina pod zámkovú dlažbu',
            },
        ],
    },
    {
        name: 'POVRCHY PODLÁH',
        details: [
            {
                name: 'miestnosti',
                description:
                    'keramické dlažby v kúpeľni a WC - výber z viacerých farieb a dekorov, laminátová plávajúca podlaha hr. 8 mm - výber z viacerých dekorov',
                isIncluded1: false,
            },
            {
                name: 'vonkajší prekryté závetrie',
                description: 'betónová zámková dlažba',
                isIncluded1: false,
            },
            {
                name: 'kryté parkovanie',
                description: 'betónová zámková dlažba',
                isIncluded1: false,
            },
        ],
    },
    {
        name: 'OBKLADY VNÚTORNÉ',
        details: [
            {
                name: 'steny',
                description:
                    'keramické obklady v kúpeľni a WC - výber z viacerých farieb a dekorov',
                isIncluded1: false,
            },
        ],
    },
    {
        name: 'VÝPLNE OTVOROV',
        details: [
            {
                name: 'okná',
                description:
                    'plastové viackomorové, izolačné trojsklo, farba z exteriéru podľa projektu a z interiéru biela',
            },
            {
                name: 'zasklené steny',
                description:
                    'plastové viackomorové, izolačné trojsklo, farba z exteriéru podľa projektu a z interiéru biela',
            },
        ],
    },
    {
        name: 'PARAPETY',
        details: [
            {
                name: 'vonkajšie',
                description: 'plech s povrchovou úpravou farba podľa projektu',
            },
            {
                name: 'vnútorné',
                description: 'drevotrieskové, resp. plastové dosky farba biela',
            },
        ],
    },
    {
        name: 'DVERE VSTUPNÉ',
        details: [
            {
                name: 'vstupné dvere',
                description:
                    'plastové v 1/3 s izolačným trojskom v úprave Satin (mliečne), farba z exteriéru podľa projektu a z interiéru biela',
            },
        ],
    },
    {
        name: 'DVERE VNÚTORNÉ',
        details: [
            {
                name: 'vnútorné dvere',
                description:
                    'dvere hladké plné fóliované s obložkovou zárubňou - výber z viacerých dekorov',
                isIncluded1: false,
            },
        ],
    },
    {
        name: 'ZDRAVOTECHNIKA - KANALIZÁCIA',
        details: [
            {
                name: 'splašková kanalizácia',
                description: 'vnútorná splašková kanalizácia - PVC potrubie',
            },
        ],
    },
    {
        name: 'ZDRAVOTECHNIKA - VODOINŠTALÁCIE',
        details: [
            {
                name: 'vodovod',
                description: 'vnútorné plasto-hliníkové rozvody k zariaďovacím predmetom',
            },
            {
                name: 'príprava TÚV',
                description: 'elektrický akumulačný zásobník TÚV ',
            },
            {
                name: '',
                description: 'predpríprava na ohrev TÚV cez fotovoltaické panely na streche',
            },
        ],
    },
    {
        name: 'ZDRAVOTECHNIKA - ZARIAĎOVACIE PREDMETY',
        details: [
            {
                name: 'hygienické miestnosti',
                description: 'vaňa, umývadlá, závesné WC, sprcha podľa projektu',
                isIncluded1: false,
            },
            {
                name: '',
                description: 'vodovodné batérie v kúpeľni a WC',
                isIncluded1: false,
            },
        ],
    },
    {
        name: 'VYKUROVANIE',
        details: [
            {
                name: 'vykurovacie telesá',
                description: 'podlahový elektrický systém vykurovania vykurovacou fóliou',
            },
        ],
    },
    {
        name: 'ELEKTROINŠTALÁCIA - SILNOPRÚD',
        details: [
            {
                name: 'silnoprúd - rozvody',
                description: 'medené rozvody pod omietkou',
            },
            {
                name: 'rozvádzač',
                description: 'osadený pod omietkou',
            },
            {
                name: 'zásuvky a vypínače',
                description: 'v bielej farbe',
            },
            {
                name: 'vnútorné osvetlenie',
                description: 'pripravenosť pre svietidlá podľa projektu',
            },
            {
                name: 'digestor',
                description: 'rozvod pre digestor v kuchyni podľa projektu',
            },
            {
                name: 'bleskozvod',
                description:
                    'zberná sústava na plochej streche vedená k uzemneniu uloženom v základovej ryhe',
            },
            {
                name: 'vonkajšie osvetlenie',
                description:
                    'pripravenosť pre svietidlá na osvetlenie vstupu, terasy a krytého státia',
            },
        ],
    },
    {
        name: 'ELEKTROINŠTALÁCIA - SLABOPRÚD',
        details: [
            {
                name: 'slaboprúd - rozvody NN',
                description:
                    'koncová krabica a zatrubkovanie v každej obytnej miestnosti podľa projektu (rozvody sú riešené z dátového rozvádzača SLOVAK TELEKOM)',
            },
        ],
    },
    {
        name: 'SPEVNENÉ PLOCHY',
        details: [
            {
                name: 'terasa',
                description: 'betónová mazanina',
            },
            {
                name: 'odkvapový chodník',
                description: 'drvený triedený štrk',
            },
            {
                name: 'vjazd od komunikácie',
                description: 'štrkové lôžko',
            },
        ],
    },
    {
        name: 'TERÉNNE ÚPRAVY',
        details: [
            {
                name: 'pozemok',
                description: 'vyčistenie a hrubé terénne úpravy po stavebných a výkopových prácach',
            },
        ],
    },
    {
        name: 'OPLOTENIE',
        details: [
            {
                name: 'oporné múry',
                description: 'oporné múry z DT tvárnic medzi pozemkami (bez oplotenia)',
            },
        ],
    },
    {
        name: 'PRÍPOJKY',
        details: [
            {
                name: 'elektrická prípojka',
                description: 'elektrická prípojka vrátane elektromerového rozvádzača',
            },
            {
                name: 'vodovodná prípojka',
                description: 'vodomerná prípojka vrátane vodomernej šachty',
            },
            {
                name: 'kanalizačná prípojka',
                description:
                    'kanalizačná prípojka splaškovej kanalizácie vrátane kontrolnej šachty',
            },
        ],
    },
];
