import React from 'react';
import { Container, Row, Col, Card, CardDeck, Button } from 'react-bootstrap';

import { SlideShow } from 'src/components/SlideShow';
import { Translation } from 'src/language/Translation';

export const HomePage = () => (
    <Container className="pb-3">
        <h1 className="text-center m-4">{Translation.t('home.introduction')}</h1>

        <Row>
            <Col>
                <SlideShow />
            </Col>
        </Row>

        <CardDeck className="pt-3">
            <Card>
                <Card.Img variant="top" src="/img/FHD_Photo - 6.jpg" />
                <Card.Body>
                    <Card.Title>{Translation.t('home.cards[0].title')}</Card.Title>
                    <Card.Text className="text-justify">
                        {Translation.t('home.cards[0].description')}
                    </Card.Text>
                    <Button href="/about" variant="primary">
                        {Translation.t('home.cards[0].button')}
                    </Button>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src="/img/FHD_Photo - 100.jpg" />
                <Card.Body>
                    <Card.Title>{Translation.t('home.cards[1].title')}</Card.Title>
                    <Card.Text className="text-justify">
                        {Translation.t('home.cards[1].description')}
                    </Card.Text>
                    <Button href="/locality" variant="primary">
                        {Translation.t('home.cards[1].button')}
                    </Button>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src="/img/monoB/FHD_Photo - 90.jpg" />
                <Card.Body>
                    <Card.Title>{Translation.t('home.cards[2].title')}</Card.Title>
                    <Card.Text className="text-justify">
                        {Translation.t('home.cards[2].description')}
                    </Card.Text>
                    <Button href="/contact" variant="primary">
                        {Translation.t('home.cards[2].button')}
                    </Button>
                </Card.Body>
            </Card>
        </CardDeck>
    </Container>
);
