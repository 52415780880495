import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Fade, Row, Table } from 'react-bootstrap';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import { useParams } from 'react-router-dom';
import { AreaFormat } from 'src/components/AreaFormat';
import { MoneyFormat } from 'src/components/MoneyFormat';
import { StateLabel } from 'src/components/StateLabel';
import { STATE } from 'src/data/houses';

import { Translation } from 'src/language/Translation';
import { getHouse } from 'src/pages/houseDetail/HouseDetailPageService';

const IncludedIcon = FcCheckmark;
const NotIncludedIcon = FcCancel;

const STANDARD1_NAME = 'Holodom';
const STANDARD2_NAME = 'Dom na kľúč';

export const HouseDetailPage = () => {
    let { houseName } = useParams<{ houseName: string }>();
    const house = getHouse(houseName);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

    const handleDetailButtonClick = useCallback(() => {
        setIsDetailOpen(!isDetailOpen);
    }, [isDetailOpen]);

    if (!house) {
        return null;
    }

    return (
        <Container className="pb-3">
            <h1 className="text-center m-4">
                {Translation.t('houseDetail.headline')} {house.name}
            </h1>

            <Row>
                <Col md={7}>
                    <div className="position-relative">
                        <Carousel
                            views={house.images.map(image => ({ source: image }))}
                            modalProps={{
                                isFullscreen: isModalOpen,
                                allowFullscreen: true,
                                toggleFullscreen: () => setIsModalOpen(true),
                            }}
                            components={{
                                HeaderClose: () => null,
                                FooterCount: ({ currentIndex = 0 }) => (
                                    <>{`${currentIndex + 1} z ${house?.images.length}`}</>
                                ),
                            }}
                            trackProps={{ onViewChange: view => setSelectedPhotoIndex(view) }}
                            currentIndex={selectedPhotoIndex}
                        />

                        <ModalGateway>
                            {isModalOpen && (
                                <Modal onClose={() => setIsModalOpen(false)}>
                                    <Carousel
                                        views={house.images.map(image => ({
                                            source: image,
                                        }))}
                                        currentIndex={selectedPhotoIndex}
                                        trackProps={{
                                            onViewChange: view => setSelectedPhotoIndex(view),
                                        }}
                                    />
                                </Modal>
                            )}
                        </ModalGateway>
                    </div>
                </Col>
                <Col>
                    <Table responsive>
                        <tbody>
                            {house.state && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.state')}</td>
                                    <td>
                                        <StateLabel state={house.state} />
                                    </td>
                                </tr>
                            )}
                            {house.type && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.type')}</td>
                                    <td>{house.type}</td>
                                </tr>
                            )}
                            {house.commissioning && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.commissioning')}</td>
                                    <td>{house.commissioning}</td>
                                </tr>
                            )}
                            {house.rooms && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.rooms')}</td>
                                    <td>{house.rooms}</td>
                                </tr>
                            )}
                            {house.usefulArea && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.usefulArea')}</td>
                                    <td>
                                        <AreaFormat value={house.usefulArea} />
                                    </td>
                                </tr>
                            )}
                            {house.totalHouseArea && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.totalHouseArea')}</td>
                                    <td>
                                        <AreaFormat value={house.totalHouseArea} />
                                    </td>
                                </tr>
                            )}
                            {house.terraceArea > 0 && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.terraceArea')}</td>
                                    {Array.isArray(house.terraceArea) && (
                                        <td>
                                            {house.terraceArea.map((area, index) => (
                                                <>
                                                    <AreaFormat value={area} />
                                                    {index !==
                                                        (house.terraceArea as number[]).length -
                                                            1 && ' + '}
                                                </>
                                            ))}
                                        </td>
                                    )}
                                    {!Array.isArray(house.terraceArea) && (
                                        <td>
                                            <AreaFormat value={house.terraceArea} />
                                        </td>
                                    )}
                                </tr>
                            )}
                            {house.landArea && (
                                <tr>
                                    <td>{Translation.t('houseDetail.table.landArea')}</td>
                                    <td>
                                        <AreaFormat value={house.landArea} />
                                    </td>
                                </tr>
                            )}
                            {house.state !== STATE.SOLD && (
                                <>
                                    {house.price && (
                                        <tr>
                                            <td>{Translation.t('houseDetail.table.price')}</td>
                                            <td style={{ fontWeight: 'bold' }}>
                                                <MoneyFormat value={house.price} />
                                            </td>
                                        </tr>
                                    )}
                                    {house.priceExtra && (
                                        <tr>
                                            <td>{Translation.t('houseDetail.table.priceExtra')}</td>
                                            <td style={{ fontWeight: 'bold' }}>
                                                <MoneyFormat value={house.priceExtra} />
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </Table>

                    {house.pdf && (
                        <Button
                            variant="primary"
                            block
                            href={`/pdf/${house.pdf}.pdf`}
                            target="_blank"
                        >
                            {Translation.t('houseDetail.downloadPdfButton')}
                        </Button>
                    )}
                    {house.pdfFloor0 && (
                        <Button
                            variant="primary"
                            block
                            href={`/pdf/${house.pdfFloor0}.pdf`}
                            target="_blank"
                        >
                            {Translation.t('houseDetail.downloadPdfFloor0Button')}
                        </Button>
                    )}

                    {house.pdfFloor1 && (
                        <Button
                            variant="primary"
                            block
                            href={`/pdf/${house.pdfFloor1}.pdf`}
                            target="_blank"
                        >
                            {Translation.t('houseDetail.downloadPdfFloor1Button')}
                        </Button>
                    )}
                </Col>
            </Row>

            <hr />

            <Row>
                <Col>
                    <h2>{Translation.t('houseDetail.descriptionHeadline')}</h2>
                    <p
                        className="text-justify"
                        dangerouslySetInnerHTML={{
                            __html: house.description.replaceAll('\n', '<br /><br />'),
                        }}
                    />

                    {!isDetailOpen && (
                        <Button variant="secondary" block onClick={handleDetailButtonClick}>
                            Zobraziť štandard
                        </Button>
                    )}

                    <Fade in={isDetailOpen}>
                        <div className="mt-3">
                            <Table responsive borderless>
                                <thead>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className="text-nowrap">{STANDARD1_NAME}</th>
                                    <th className="text-nowrap">{STANDARD2_NAME}</th>
                                </thead>
                                <tbody>
                                    {house.standardData?.map(row => (
                                        <>
                                            {row.details.map(
                                                (
                                                    {
                                                        name,
                                                        description,
                                                        isIncluded1 = true,
                                                        isIncluded2 = true,
                                                    },
                                                    index,
                                                ) => (
                                                    <tr
                                                        className={`${index === 0 && 'border-top'}`}
                                                    >
                                                        <td className="font-weight-bold">
                                                            {index === 0 && row.name}
                                                        </td>
                                                        <td>{name}</td>
                                                        <td>{description}</td>
                                                        <td className="text-center">
                                                            {isIncluded1 ? (
                                                                <IncludedIcon />
                                                            ) : (
                                                                <NotIncludedIcon />
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            {isIncluded2 ? (
                                                                <IncludedIcon />
                                                            ) : (
                                                                <NotIncludedIcon />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Fade>
                </Col>
            </Row>
        </Container>
    );
};
