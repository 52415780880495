import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { GalleryPage } from 'src/pages/GalleryPage';

import { HomePage } from 'src/pages/HomePage';
import { HouseDetailPage } from 'src/pages/houseDetail/HouseDetailPage';
import { LocalityPage } from 'src/pages/LocalityPage';
import { AboutPage } from 'src/pages/AboutPage';
import { ContactPage } from 'src/pages/ContactPage';
import { PricesPage } from 'src/pages/PricesPage';

export const Content = () => (
    <main role="main" className="flex-shrink-0">
        <Switch>
            <Route exact path="/">
                <HomePage />
            </Route>
            <Route path="/locality">
                <LocalityPage />
            </Route>
            <Route path="/about">
                <AboutPage />
            </Route>
            <Route path="/contact">
                <ContactPage />
            </Route>
            <Route path="/house/:houseName">
                <HouseDetailPage />
            </Route>
            <Route path="/prices">
                <PricesPage />
            </Route>
            <Route path="/gallery">
                <GalleryPage />
            </Route>
        </Switch>
    </main>
);
